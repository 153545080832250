// 提示 问题汇总等
<template>
  <div class="tipsAre">
    <el-input type="textarea" placeholder="请输入" v-model="textarea" maxlength="200" show-word-limit rows='5'></el-input>
  </div>
</template>
<script>
export default {
  data () {
    return {
      textarea: ''
    }
  }
}
</script>
<style lang="scss" scoped>
  .tipsAre{
    padding: 20px 10px;
  }
</style>
